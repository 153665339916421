(function () {
  "use strict";

  var suffix = window.location.origin.split(".");
  suffix = suffix[suffix.length - 1];

  angular.module("EntrakV5").constant("IS_LOCAL", "");
  angular.module("EntrakV5").constant("URL", "https://dev.api.en-trak.com/");
  angular
    .module("EntrakV5")
    .constant(
      "LOGIN_URL",
      "https://dev.accounts.en-trak." + suffix + "/#!/login/landlord"
    );
  angular.module("EntrakV5").constant("REFRESH_URL", "https://dev.accounts.en-trak.com/#!/refresh/landlord?cb=");
  angular.module("EntrakV5").constant("EMS_URL", "https://dev-v4.en-trak.com");
  angular
    .module("EntrakV5")
    .constant(
      "MS_CONSENT",
      "https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=bd971ab3-6cf0-4a1d-9ba2-b815fcd3ae2b&state=secretClientValue"
    ); //&redirect_uri=https://ms.en-trak.com/auth/callback
  angular.module("EntrakV5").constant("COOKIE_PREFIX", "dev_");
})();
